<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >系统管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>消息信息</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">消息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="120px"
                ref="queryFormRef"
            >
                <el-row :gutter="10">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择类型"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                v-for="item in dict_season"
                                :key="item.dictCode"
                                :label="item.dictLabel"
                                :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否处理" prop="state">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.state"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_state"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="类型" prop="types">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.types"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_types"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="getAllLogsList('search')"
                            >查询</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            plain
                            style="width: 100%"
                            @click="reset"
                            >重置</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- 问题列表 -->
        <el-card class="list_card" style="margin-top: 20px">
            <el-row class="title_row">
                <el-col :span="12">
                    <div class="list_title title_class">消息列表</div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="问题单词" prop="word"></el-table-column>
                <el-table-column
                    label="问题描述"
                    prop="describe"
                ></el-table-column>
                <el-table-column label="问题类型" prop="type"></el-table-column>
                <el-table-column label="处理状态" prop="state">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.state == true"
                            >已处理</el-tag
                        >
                        <el-tag type="danger" v-else>未处理</el-tag>
                    </template></el-table-column
                >
                <el-table-column
                    label="反馈"
                    prop="reply"
                ></el-table-column>
                <el-table-column
                    label="创建人"
                    prop="userName"
                ></el-table-column>
                <el-table-column
                    label="联系方式"
                    prop="phone"
                ></el-table-column>
                <el-table-column
                    label="创建时间"
                    prop="submitTime"
                ></el-table-column>
                <el-table-column label="操作" width="80px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            @click="changeStateDetailData(scope.row.id)"
                            >消息回复</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!--  -->
        <el-dialog
            title="消息回复"
            :visible.sync="messageDialogVisible"
            width="40%"
            @close="messageDialogVisibleClose"
        >
            <el-form
                label-width="110px"
                :model="messageForm"
                :rules="messageFormRules"
                ref="messageFormRef"
            >
                <el-row :gutter="20">
                    <el-col :span="20">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="messageForm.reply"
                        >
                        </el-input>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="messageDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="saveMessageClicked"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { wordFeedbackList, wordFeedbackReply } from '@/http/api'
export default {
  data () {
    return {
      messageDialogVisible: false,
      dataList: [],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dateTimeRange: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        season: ''
      },
      messageFormRules: {
        textarea: [{ required: true, message: '请输入反馈信息', trigger: 'blur' }]
      },
      messageForm: {
      },
      total: 0,
      dict_state: this.$userInfo.isHandle(),
      dict_types: this.$userInfo.feedBackWordType()
    }
  },
  created () {
    this.getAllLogsList()
  },
  methods: {
    getAllLogsList (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      wordFeedbackList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getAllLogsList('search')
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getAllLogsList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getAllLogsList()
    },
    changeStateDetailData (messageId) {
      this.messageDialogVisible = true
      this.messageForm.id = messageId
    },
    saveMessageClicked () {
      wordFeedbackReply(this.messageForm).then((res) => {
        this.messageDialogVisible = false
        this.$message.success('保存成功！')
        this.getAllLogsList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    messageDialogVisibleClose () {
      this.$refs.messageFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.a-btn {
    color: #409eff;
    font-size: 14px;
}
.row {
    display: flex;
    margin-bottom: 12px;
}
</style>
